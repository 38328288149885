<template>
  <div class="m-delivery-date-labels delivery-date">
    <div class="delivery-date__container desktop-only">
      <transition name="text-fade" v-for="label in labels" :key="label">
        <div v-if="label !== ''" v-show="activeLabel === label" class="delivery-date__label">
          {{ label }}
        </div>
      </transition>
    </div>
    <div class="delivery-date__container smartphone-only">
      <transition name="text-fade" v-for="label in labels" :key="label">
        <div v-if="label !== ''" v-show="activeLabel === label" class="delivery-date__label">
          {{ label }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import config from 'config';
import { mapState } from 'vuex';

const SLIDES_CHANGE_INTERVAL = config.deliveryDate.delivery_date_interval_endpoint * 1000
const DEFAULT_LABEL = config.deliveryLabels.defaultLabel
export default {
  name: 'MDeliveryDateLabels',
  data () {
    return {
      activeLabel: this.$t(DEFAULT_LABEL),
      activeLabelMobile: this.$t(DEFAULT_LABEL)
    }
  },
  computed: {
    ...mapState({
      deliveryDate: (state) => state.ui.deliveryDate
    }),
    labels () {
      if (!this.deliveryDate?.desktop?.labels?.length) return [this.$t(DEFAULT_LABEL)]
      return this.deliveryDate?.desktop?.labels
    },
    labelsMobile () {
      if (!this.deliveryDate?.mobile?.labels?.length) return [this.$t(DEFAULT_LABEL)]
      return this.deliveryDate?.mobile?.labels
    },
    labelsCount () {
      return this.labels ? this.labels.length : 0
    }
  },
  methods: {
    nextLabel (n) {
      this.activeLabel = this.labels[n]
    },
    nextLabelMobile (n) {
      this.activeLabelMobile = this.labelsMobile[n]
    },
    async initiateLabels () {
      await this.$store.dispatch('ui/initiateDeliveryDateLabels')
      let i = 0
      this.nextLabel(i)
      this.nextLabelMobile(i)
      setInterval(() => { // run delivery date label text slider
        this.nextLabel(++i % (this.labelsCount))
        this.nextLabelMobile(++i % (this.labelsCount))
      }, SLIDES_CHANGE_INTERVAL);
    }
  },
  mounted () {
    this.initiateLabels()
  }
};
</script>

<style lang="scss" scoped>
.delivery-date {
  &__container {
    font-family: var(--font-family--primary);
    font-weight: var(--font-weight--semibold);
    position: relative;
    display: flex;
  }
  &__label {
    width: calc(100vw - var(--spacer-xl));
    text-align: center;
    color: var(--c-header-text-color);
  }
}
.text-fade-enter-active,
.text-fade-leave-active {
  transition: all 6s;
  transition-timing-function: cubic-bezier(0.8, 0, 0.1, 1);
  overflow: hidden;
}
.text-fade-leave-active {
  top: 0;
  position: absolute;
}
.text-fade-leave-active {
  opacity: 0;
  transform: translate(0, -1em);
}
.text-fade-enter {
  opacity: 0;
  transform: translate(0, 1em);
}
</style>
