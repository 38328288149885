<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6"
      y="12"
      width="20"
      height="2"
      rx="1"
      :fill="color"
    />
    <rect
      x="6"
      y="19"
      width="20"
      height="2"
      rx="1"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'AMenuIcon',
  props: {
    color: {
      type: String,
      default: '#43464E'
    }
  }
};
</script>
