<template>
  <div>
    <div class="o-header-hidden--spacer" />
    <div class="o-header" :class="{ 'is-sticky': isSearchPanelVisible, 'o-header-hidden': !showHeader }">
      <SfOverlay :visible="!isSidebarOpen && isSearchPanelVisible" @click="$store.commit('ui/setSearchpanel', false)" />
      <div class="sf-bar smartphone-only">
        <MDeliveryDateLabels />
      </div>
      <SfHeader :active-icon="activeIcon">
        <template #logo>
          <div class="sf-header__menu">
            <SfButton
              :aria-label="$t('Header Menu Desktop')"
              class="sf-button--pure a-header-menu-icon"
              @click="toggleMenuSidebar"
            >
              <AMenuIcon color="var(--header-icon-color)" />
              <span class="sr-only">header icon</span>
            </SfButton>
            <SfButton
              :aria-label="$t('Header Menu Mobile')"
              class="sf-button--pure a-search-icon sf-header__action smartphone-only"
              @click="toggleSearchSidebar"
            >
              <SfIcon icon="search" size="xs" color="var(--header-icon-color)" />
              <span class="sr-only">header icon</span>
            </SfButton>
          </div>
          <ALogo @click.native="closeSidebar" />
        </template>
        <template #search>
          <div class="search-container">
            <div class="search-wrapper">
              <OSearch class="desktop-only" />
            </div>
          </div>
        </template>
        <template #header-icons>
          <div class="sf-header__icons">
            <AMicrocartIcon class="sf-header__action" />
          </div>
          <div class="sf-header_button_checkout">
            <router-link v-if="totalQuantity" class="sf-button" :to="localizedRoute('/checkout')">
              {{ $t('TO CHECKOUT') }}
            </router-link>
            <span class="sf-button" v-else>
              {{ $t('TO CHECKOUT') }}
            </span>
          </div>
          <ALanguageSwitcherIcon class="desktop-only" />
        </template>
      </SfHeader>
      <div class="sf-bar desktop-only">
        <MDeliveryDateLabels />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SfHeader from '@storefront-ui/vue/src/components/organisms/SfHeader/SfHeader.vue';
import SfOverlay from '@storefront-ui/vue/src/components/atoms/SfOverlay/SfOverlay.vue';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';

import ALogo from '~/theme/components/atoms/a-logo';
import AMenuIcon from '~/theme/components/atoms/a-menu-icon';
import MDeliveryDateLabels from '~/theme/components/molecules/m-delivery-date-labels';
import AMicrocartIcon from '~/theme/components/atoms/a-microcart-icon';
import ALanguageSwitcherIcon from '~/theme/components/atoms/a-language-switcher-icon';
import OSearch from '~/theme/components/organisms/o-search';
import { mapMobileObserver, unMapMobileObserver } from '~/theme/helpers/mobile-observer';
import { isServer } from '@vue-storefront/core/helpers';
export default {
  name: 'OHeader',
  components: {
    AMenuIcon,
    MDeliveryDateLabels,
    SfHeader,
    SfButton,
    ALogo,
    AMicrocartIcon,
    OSearch,
    SfIcon,
    SfOverlay,
    ALanguageSwitcherIcon
  },
  data () {
    return {
      showHeader: true,
      lastScrollPosition: 0
    }
  },
  computed: {
    ...mapState({
      isSidebarOpen: state => state.ui.sidebar,
      isWishlistOpen: state => state.ui.wishlist,
      isSearchPanelVisible: state => state.ui.searchpanel,
      isMicrocartOpen: state => state.ui.microcart
    }),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      totalQuantity: 'cart/getItemsTotalQuantity'
    }),
    ...mapMobileObserver(),
    isOnMobile () {
      if (this.isServer) return
      return this.isMobile
    },
    activeIcon () {
      return this.isLoggedIn ? 'account' : '';
    }
  },
  methods: {
    toggleMenuSidebar () {
      this.$store.dispatch('ui/toggleSidebar')
    },
    toggleSearchSidebar () {
      this.toggleMenuSidebar()
      this.$store.commit('ui/setSidebarSearchFocus', true)
    },
    closeSidebar () {
      this.$store.dispatch('ui/toggleSidebar', false)
    },
    onScroll () {
      if (!this.isOnMobile) return
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) return
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) return
      this.showHeader = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    },
    hideHeaderWhenPanelOpen (bool) {
      if (!bool) {
        this.showHeader = true;
        return
      }
      this.showHeader = false
    }
  },
  watch: {
    isMicrocartOpen (val) {
      this.hideHeaderWhenPanelOpen(val)
    },
    isWishlistOpen (val) {
      this.hideHeaderWhenPanelOpen(val)
    },
    $route () {
      if (this.isSearchPanelVisible) {
        this.$store.commit('ui/setSearchpanel', false)
        this.closeSidebar()
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
    unMapMobileObserver()
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

::v-deep .sf-overlay {
  position: absolute;
  top: var(--header-full-height);
  z-index: var(--overlay-search-z-index);
}

.o-header-hidden--spacer{
  height: calc(var(--header-main-height) + var(--header-topbar-height));
  @include for-desktop {
    display: none;
  }
}

.o-header {
  --header-height: var(--header-main-height);
  --header-height--desktop: var(--header-main-height);
  --header-navigation-item-margin: 0 2rem 0 0;
  --items-spacing: var(--spacer-base);
  @include for-desktop {
    --items-spacing: var(--spacer-xl);
  }
  @include for-mobile {
    position: fixed;
    width: 100%;
    z-index: 10;
    transform: translate3d(0, -100%, 0);
    transition: 0.2s all ease-out;
    &-hidden {
      transform: translate3d(0, -200%, 0);
    }
  }

  .search-container {
    --search-bar-border: solid var(--c-light);
    --search-bar-placeholder-color: var(--c-text-primary);
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    margin: 0;
    margin-right: calc(var(--spacer-base) * 2);
    .o-search {
      flex-grow: 1;
    }
    .o-search-cancel {
      padding-right: var(--spacer-sm);
      font-size: var(--font-size--xs);
    }
  }

  .sf-bar {
    --bar-height: var(--header-topbar-height);
    --bar-font-size: var(--font-size--xs);
    background-color: var(--c-theme-color);
    color: var(--c-white);
    ::v-deep .sf-link {
      --link-color: var(--c-white);
      --c-link-hover: var(--c-light);
    }
    @include for-desktop {
      --bar-font-size: var(--font-size--sm);
    }
  }

  ::v-deep .sf-header {
    &__menu {
      display: flex;
      width: 30%;
      @include for-desktop {
        width: auto;
      }
      .a-header-menu-icon {
        width: 2rem;
        min-width: 2rem;
        margin-right: var(--items-spacing);
      }
    }
    &__actions {
      flex: unset;
      width: 30%;
      @include for-desktop {
        flex: 100%;
        width: auto;
      }
    }
    &__action {
      margin: 0;
      margin-right: var(--items-spacing);
      &.a-microcart-icon {
        margin: 0;
      }
    }
    &__header {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      height: var(--header-main-height);
    }
    @include for-desktop {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
    &__icons {
      display: flex;
      .sf-header__icon {
        cursor: pointer;
      }
    }
    &_button_checkout {
      margin-right: 10px;
      @include for-mobile {
        display: none;
      }
      .sf-button {
        padding: 12px 20px;
        font-size: 14px;
        background-color: var(--c-theme-color);
        color: var(--c-header-text-color) !important;
      }
      a {
        margin-left: 18px;
      }
      span.sf-button {
        background-color: transparent;
        border: 1px solid var(--c-theme-color);
        color: var(--c-header-text-color) !important;
        cursor: auto;
      }
    }
  }
}
</style>
<style lang="scss" >
 .sf-header .sf-badge {
   color: var(--c-header-text-color);
 }
</style>
