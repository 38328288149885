<template>
  <SfButton
    class="sf-button--pure a-microcart-icon"
    :class="!totalQuantity && 'empty'"
    @click="toggleMicrocart"
    :aria-label="$t('Open microcart')"
  >
    <AIcon
      size="xs"
      :icon="'shop-cart'"
      class="sf-header__icon"
    />
    <SfBadge class="sf-icon__badge sf-badge--number" v-show="!isServer && !!totalQuantity">
      {{ totalQuantity }}
    </SfBadge>
    <span class="sr-only">microcart icon</span>
  </SfButton>
</template>

<script>
import { isServer } from '@vue-storefront/core/helpers'
import { mapGetters } from 'vuex';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfBadge from '@storefront-ui/vue/src/components/atoms/SfBadge/SfBadge.vue';

import AIcon from './a-icon'

export default {
  components: { AIcon, SfButton, SfBadge },
  data () {
    return {
      isServer
    }
  },
  computed: {
    ...mapGetters({
      totalQuantity: 'cart/getItemsTotalQuantity'
    })
  },
  methods: {
    toggleMicrocart () {
      this.$store.dispatch('ui/toggleMicrocart')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.a-microcart-icon {
  position: relative;

  &:not(.empty) {
    border: 2px solid var(--c-theme-color);
    border-radius: 999px;
  }

  .sf-badge {
    --badge-background: var(--c-theme-color);
    --badge-font-line-height: 1.1;
    --icon-badge-top: .1rem;
    --icon-badge-right: .2rem;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include for-desktop {
      --badge-font-line-height: 1;
      --icon-badge-right: .4rem;
    }
  }

  .sf-header__icon {
    color: var(--header-icon-color);
    cursor: pointer;
    margin: 3px 12px;

    @include for-desktop {
      margin: 3px 18px;
      height: 30px;
      width: 28px;
    }
  }
}
</style>
